import logo from './logo.svg';
import './App.css';
import { FaLinkedin, FaInstagram,FaInstagramSquare, FaMedium, FaEnvelope } from 'react-icons/fa';
import { IoCalendar, IoMail } from 'react-icons/io5'
import { FiMail, FiCalendar } from "react-icons/fi"

function App() {
  return (
    <div className="App">
      <div style={{ display: "flex", backgroundColor: "transparent",justifyContent: "space-between", flexWrap: "wrap", paddingBottom: 80, position:"sticky", top: 20, padding: "6%"  }}>
          <div style={{ minWidth: 250 }}>
            <h2 style={{ margin: 0, backgroundColor: "#ef476f", marginBottom: 20}}>sophia bender koning</h2>
          </div>
          <div style={{ backgroundColor: "#ef476f"}} >
            <a href="https://www.linkedin.com/in/sophiabk" target="_blank"><FaLinkedin color="white" size={30} style={{ marginRight: 10, marginTop: -2, marginLeft: 0 }}/></a>
            <a href="https://medium.com/@sophiabk" target="_blank"><FaMedium color="white" size={30} style={{ marginRight: 10, marginLeft:0, marginTop: -2 }}/></a>
            <a target="_blank" href="mailto:me@sophiabk.com" ><FiMail color="#ef476f" size={22} style={{ marginRight: 10, marginLeft: 0, backgroundColor: "white", padding: 2, paddingRight: 4, paddingLeft: 4, position: "relative", top: -2, borderRadius: 2 }}/></a>
          </div>
        </div>
      <header className="App-header">
        <div style={{ display: "flex", justifyContent: "center"}}>
          <h1 style={{ maxWidth: 800, fontSize: 80, margin: 0, paddingBottom: 100, paddingTop: 50, paddingLeft: 50, paddingRight: 50}}>
            I create tech for mission-driven startups to help them take over the world
          </h1>
        </div>
      </header>
      <div style={{ marginBottom: 100, marginRight: 20, marginLeft: 20 }}>
        <div style={{ padding: "5%", display: "flex", justifyContent: "center"}}>
          <div style={{ display: "flex", justifyContent: "space-between", maxWidth: 800, flex: 1, flexWrap: "wrap" }}>
               <div style={{ flex: 2}}><div style={{ backgroundColor: "white", width: 100, height: 1, marginTop: 8}}>
               </div>
               </div>

              <p style={{  flex: 2, margin: 0 }}>I excel at translating technical concepts to the non-techy. I enjoy whittling down a big problem to the right size solution. And I love listening to real people and their real pain points.</p>
          </div>
        </div>
        <div style={{ padding: "2%", display: "flex", justifyContent: "center"}}>
          <div style={{ display: "flex", justifyContent: "space-between", maxWidth: 800, flex: 1 }}>
              <div style={{ flex: 2}}>
                <p style={{ fontWeight: "bold", textAlign: "left", marginBottom: 0 }}>Product Manager</p>
                <p style={{ opacity: 0.8, fontSize: 12, margin: 0 }}>2012 - 2018</p>
              </div>
            <div style={{ flex: 2 }}>
              <p>I created edtech products for web and mobile that millions of students and teachers love and learn from.</p>
            </div>
          </div>
        </div>
        
        <div style={{ padding: "2%", display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
          <div style={{ display: "flex", justifyContent: "space-between", maxWidth: 800, flex: 1 }}>
              <div style={{ flex: 2 }}>
                <p style={{ fontWeight: "bold", textAlign: "left", marginBottom: 0 }}>Engineer & Founder</p>
                <p style={{ opacity: 0.8, fontSize: 12, margin: 0 }}>2018 - 2022</p>
              </div>
            <div style={{ flex: 2 }}>
              <p>I built mobile apps for new parents that try to alievitate anxiety rather than simply profit from it.</p>
            </div>
          </div>
        </div>
        <div style={{ padding: "2%", display: "flex", justifyContent: "center", flexWrap: "wrap",}}>
          <div style={{ display: "flex", justifyContent: "space-between", maxWidth: 800, flex: 1 }}>
              <div style={{ flex: 2}}>
                <p style={{ fontWeight: "bold", textAlign: "left", marginBottom: 0 }}>Consultant</p>
                <p style={{ opacity: 0.8, fontSize: 12, margin: 0 }}>2022 to present</p>
              </div>
            <div style={{flex: 2 }}>
              <p>Bring your big ideas and we'll craft them into well-defined products, build them in code, and get them out to real people.</p>
            </div>
          </div>
        </div>
      </div>
      <footer style={{ display: "flex", flex: 1, justifyContent: "center", backgroundColor: "white", padding: 50}}>
        <div style={{ flex: 1, maxWidth: 800}}>
          <div style={{ display: "flex", justifyContent: "flex-start"}}>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between"}}>
            <a target="_blank" href="mailto:me@sophiabk.com" style={{ textDecoration: "none" }}><p style={{ fontWeight: "bold", color: "#ef476f"}}>me@sophiabk.com</p></a>
            <p style={{ color: "#ef476f" }}>👋 See ya!</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
